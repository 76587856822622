(<template>
  <div class="assignment-print-footer">
    <div class="assignment-print-footer__content">
      <div class="assignment-print-footer__contacts-wrapper">
        <a :href="infoPortalLink"
           class="assignment-print-footer__contact assignment-print-footer__separator">{{ infoPortalText }}</a>
        <a :href="platformLink"
           class="assignment-print-footer__contact assignment-print-footer__separator">{{ platformText }}</a>
        <a :href="`tel:${supportPhoneText}`"
           class="assignment-print-footer__contact js-copy-to-clipboard">{{ supportPhoneText }}</a>
      </div>
      <p class="assignment-print-footer__company-info">{{ copyright }}</p>
      <p class="assignment-print-footer__company-info">{{ address }}</p>
    </div>
  </div>
</template>)

<script>
  export default {
    data() {
      return {
        platformLink: this.$getString('domainData', 'platformLink'),
        platformText: this.$getString('domainData', 'platformText'),
        infoPortalLink: this.$getString('domainData', 'infoPortalLink'),
        infoPortalText: this.$getString('domainData', 'infoPortalText'),
        supportPhone: this.$getString('domainData', 'supportPhone'),
        supportPhoneText: this.$getString('domainData', 'supportPhoneText')
      };
    },
    computed: {
      copyright() { return this.$getString('footer', 'copyright'); },
      address() { return this.$getString('footer', 'address'); }
    }
  };
</script>

<style scoped>
  .assignment-print-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: none;
    order: 2;
    width: 100%;
    padding: 10px 15px;
    border-top: 1px solid #e9eaef;
    background-color: #f1f0ee;
    color: #000;
    font-size: 12px;
  }

  .assignment-print-footer__content {
    max-width: 640px;
    margin: 0 auto;
    text-align: center;
  }

  .assignment-print-footer__contact {
    display: inline-block;
    margin-right: 15px;
    text-decoration: none;
  }

  .assignment-print-footer__separator::after {
    content: '|';
    margin-left: 15px;
  }

  .assignment-print-footer__company-info {
    padding-top: 10px;
  }
</style>
